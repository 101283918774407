<template>
  <div class="containerLabsPage pt-4 w-100">
    <custom-scrollbar>
      <b-container fluid>
        <div class="h3 mb-4">
          Отзывы консультаций
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-select
            v-model="selectedStatus"
            :options="statusOptions"
            class="w-25"
            @change="fetchReview"
          />
          <base-async-select
            v-model="selectedDoctor"
            :fetch-function="fetchDoctors"
            class="w-25 crm-form-field crm-form-field-base-select"
            placeholder="Выберите врача"
            server-paginated
            loading-title="Загрузка врачей"
            no-options-title="Врачи не найдены"
            @change="changeDoctor($event)"
          />
        </div>
        <div class="mt-4 mb-4 d-flex">
          <b-input-group class="bg-white rounded">
            <b-form-input
              v-model="patientFio"
              placeholder="Введите ФИО пациента"
              @keyup.enter="fetchReview"
            />
            <b-form-input
              v-model="consultationId"
              placeholder="ID консультации"
              @keyup.enter="fetchReview"
            />
          </b-input-group>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-2"
            @click="fetchReview"
          >
            <b-icon icon="search" />
          </b-button>
        </div>
        <b-overlay
          v-if="!reviewListStore || Object.keys(reviewListStore).length === 0"
          :show="isLoading"
          no-wrap
        />
        <transition
          mode="out-in"
          name="component-fade"
        >
          <div v-if="reviewListStore">
            <div v-if="Object.keys(reviewListStore).length !== 0">
              <b-table
                v-if="reviewListStore"
                :fields="fields"
                :filter="filter"
                :items="reviewListStore.data"
                :busy="isLoading"
                :per-page="0"
                :small="true"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="bg-white border-secondary mt-4"
                bordered
                hover
              >
                <template v-slot:cell(moderatedAt)="row">
                  <span>{{ createDateFormat(row) }}</span>
                </template>
                <template v-slot:cell(consultationId)="row">
                  <span>{{ row.item.consultationId }}</span>
                </template>
                <template v-slot:cell(moderationStatus)="row">
                  <span>{{ reviewStatus(row) }}</span>
                </template>
                <template v-slot:cell(patientFio)="row">
                  <span>{{ row.item.patientFio }}</span>
                </template>
                <template v-slot:cell(doctorFio)="row">
                  <span>{{ row.item.doctorFio }}</span>
                </template>
                <template #cell(actions)="row">
                  <router-link
                    :to="`/consultation-feedback-info/${row.item.id}`"
                  >
                    <b-button
                      class="mr-2"
                      size="sm"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                    >
                      Инфо
                    </b-button>
                  </router-link>
                </template>
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2" />
                    <strong>Загрузка...</strong>
                  </div>
                </template>
              </b-table>

              <div
                class="mt-3"
              >
                <div
                  v-show="reviewListStore"
                  class="crm-pagination"
                >
                  <b-dropdown
                    id="dropdown-1"
                    :text="`${perPage}`"
                    class="m-md-2"
                    size="sm"
                    split
                    split-variant="outline-primary"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                  >
                    <b-dropdown-item @click="tableSizeHandler(10)">
                      10
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="tableSizeHandler(20)">
                      20
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="tableSizeHandler(30)">
                      30
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="+allCount"
                    pills
                    style="padding-top: 16px"
                    @input="onClickNextPage"
                  />
                </div>
              </div>
            </div>
            <span v-else>Ничего не найдено</span>
          </div>
        </transition>
      </b-container>
    </custom-scrollbar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BaseAsyncSelect } from '@/components/base';
import * as types from '@/store/types';
import { format, parseISO } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';

export default {
  name: 'ConsultationFeedBack',
  components: {
    BaseAsyncSelect,
  },
  mixins: [mixinRoles],
  data() {
    return {
      sortBy: 'moderatedAt',
      sortDesc: true,
      fields: [
        {
          key: 'moderatedAt',
          label: 'Дата',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'consultationId',
          label: 'ID консультации',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'patientFio',
          label: 'ФИО пациента',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'doctorFio',
          label: 'ФИО врача',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'moderationStatus',
          label: 'Статус модерации',
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'Подробнее',
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
      patientFio: null,
      doctorFio: null,
      consultationId: null,
      currentPage: 1,
      perPage: 10,
      limit: 10,
      filter: null,
      isLoading: false,
      rows: 1,
      allCount: null,
      selectedStatus: 1,
      selectedDoctor: null,
      statusOptions: [
        {
          value: 1,
          text: 'На модерации',
        },
        {
          value: 2,
          text: 'Опубликован',
        },
        {
          value: 3,
          text: 'Отклонен',
        },
      ],
    };
  },
  computed: {
    paginationParams() {
      return {
        limit: this.limit,
        offSet: this.offSet,
        doctorId: this.selectedDoctor?.id,
        patientFio: this.patientFio,
        consultationId: this.consultationId,
        moderationStatus: this.selectedStatus,
      };
    },
    reviewListStore() {
      return this.$store.state.ConsultationFeedback.consultationReviewList;
    },
    ...mapGetters({
      consultationReviewList: types.CONSULTATION_REVIEW_LIST_GET,
    }),
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  beforeDestroy() {
    this.$store.commit(this.$types.REVIEW_ITEMS_SET, []);
  },
  async created() {
    await this.fetchReview();
    await this.fetchDoctors();
  },
  methods: {
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
      await this.fetchReview();
    },
    createDateFormat(row) {
      return row ? format(parseISO(row.value), 'dd.MM.yyyy') : null;
    },
    reviewStatus(row) {
      if (row.item.moderationStatus === 'Preparing') return 'Подготовка к модерации';
      if (row.item.moderationStatus === 'Moderation') return 'На модерации';
      if (row.item.moderationStatus === 'Published') return 'Опубликован ';
      if (row.item.moderationStatus === 'Rejected') return 'Отклонен ';
    },
    async fetchReview() {
      try {
        this.isLoading = true;
        const data = await this.$store.dispatch(this.$types.CONSULTATION_REVIEW_LIST_FETCH, this.paginationParams);
        this.allCount = data.xTotalCount;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDoctors({ query, skip, take } = {}) {
      const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
        skip,
        take,
        query,
        save: false,
      });

      return doctors.map((doctor) => ({ id: doctor.id, name: doctor.fullName }));
    },
    changeDoctor(doc) {
      this.doctorId = doc ? doc.id : null;
    },
    async onClickNextPage() {
      await this.fetchReview();
    },
  },
};
</script>
<style lang="scss" scoped>
.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

.crm-form-field {
  margin-right: 55px;
  background-color: #fff;
}
.crm-table {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.crm-input {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.crm-pagination {
  display: flex;
  align-items: center;
}

.crm-icon-delete {
  cursor: pointer;
  transition: .3s all;

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}

.crm-icon-edit {
  cursor: pointer;
  transition: .3s all;

  path {
    fill: $blue;
  }

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}
</style>
